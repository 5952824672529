/*
TODO: This module contains anti-patterns and should be refactored then deleted.
*/
import { Segment } from "#app/(unauthorized)/authentication/segment";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { queryOptions } from "@tanstack/react-query";
import { allQueryKeys } from "../allQueryKeys";

export function useQueryOptionsResearchHeadlinesHasAccess() {
  const jwt = useJwt();
  const hadNoRequiredActivities = jwt.requiredActivities.length === 0;
  const hasRoleAccess = [
    Segment.IndividualInvestors,
    Segment.PrivateAssetManagement,
  ].includes(jwt.segment);

  if (hadNoRequiredActivities && hasRoleAccess) {
    return queryOptions({
      queryKey:
        allQueryKeys.externalBairdResearchService.researchHeadlinesHasAccess
          .queryKey,
      queryFn: (context) =>
        allQueryKeys.externalBairdResearchService.researchHeadlinesHasAccess.queryFn(
          context,
        ),
      staleTime: Infinity,
      gcTime: 0,
      throwOnError: false,
    });
  }

  return queryOptions({
    queryKey:
      allQueryKeys.externalBairdResearchService.researchHeadlinesHasAccess
        .queryKey,
    queryFn: () => false,
    staleTime: 0,
    throwOnError: false,
  });
}
