/*
TODO: This module contains anti-patterns and should be refactored then deleted.
*/
import { Segment } from "#app/(unauthorized)/authentication/segment";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { queryOptions, useQueries, useQuery } from "@tanstack/react-query";
import { allQueryKeys } from "../allQueryKeys";
import { type GetFinancialPlanStatusData } from "./get-financial-plan-status";

const defaultFinancialPlanStatus = {
  canUseFinancialPlanning: false,
  hasVisibleDocuments: false,
  hasVisiblePlans: false,
} as const satisfies GetFinancialPlanStatusData;

function useQueryHasFinancialPlansAccess() {
  const jwt = useJwt();
  const hasRequiredActivities = jwt.requiredActivities.length > 0;
  const isAuthorized = [
    Segment.IndividualInvestors,
    Segment.PrivateAssetManagement,
    Segment.Preclient,
  ].includes(jwt.segment);
  return !hasRequiredActivities && isAuthorized;
}

export function useQueryOptionsFinancialPlansStatus() {
  const hasFinancialPlansAccess = useQueryHasFinancialPlansAccess();
  return queryOptions({
    queryKey:
      allQueryKeys.externalFinancialPlanService.financialPlanStatus.queryKey,
    queryFn: (context) =>
      hasFinancialPlansAccess
        ? (
            allQueryKeys.externalFinancialPlanService.financialPlanStatus.queryFn(
              context,
            ) as Promise<GetFinancialPlanStatusData>
          ).catch(() => defaultFinancialPlanStatus)
        : defaultFinancialPlanStatus,
    staleTime: hasFinancialPlansAccess ? Infinity : 0,
    gcTime: hasFinancialPlansAccess ? 0 : undefined,
    throwOnError: false,
  });
}

export function useQueryFinancialPlansAll() {
  const hasFinancialPlansAccess = useQueryHasFinancialPlansAccess();

  const statusResult = useQuery({
    ...allQueryKeys.externalFinancialPlanService.financialPlanStatus,
    enabled: hasFinancialPlansAccess,
  });

  const hasVisibleDocuments =
    statusResult.isSuccess && statusResult.data.hasVisibleDocuments;

  const householdsResult = useQuery({
    ...allQueryKeys.externalFinancialPlanService.households,
    enabled: hasVisibleDocuments,
  });

  const hasHouseholdsIds =
    householdsResult.isSuccess && householdsResult.data.length > 0;
  const householdsIds = hasHouseholdsIds
    ? householdsResult.data.map((el) => el.id)
    : [];

  const plansByHouseholdsIdsResult = useQueries({
    queries: householdsIds.map((householdId) => ({
      ...allQueryKeys.externalFinancialPlanService.financialPlan({
        householdId,
      }),
      enabled: hasHouseholdsIds,
    })),
    combine: (results) => {
      const isError = results.some((el) => el.isError === true);
      const isLoading = results.some((el) => el.isLoading === true);
      const isPending = results.some((el) => el.isPending === true);
      const isSuccess = results.every((el) => el.isSuccess === true);
      const data = isSuccess ? results.map((el) => el.data).flat() : undefined;
      const error = isError
        ? (results.find((el) => el.error != null) as unknown as Error)
        : null;
      return {
        data,
        error,
        isError,
        isLoading,
        isPending,
        isSuccess,
      };
    },
  });

  const data = plansByHouseholdsIdsResult.data ?? [];

  const error =
    statusResult.error ??
    householdsResult.error ??
    plansByHouseholdsIdsResult.error;

  const isPending =
    statusResult.isPending ||
    (hasVisibleDocuments &&
      (householdsResult.isPending ||
        (hasHouseholdsIds && plansByHouseholdsIdsResult.isPending)));

  const isError =
    statusResult.isError ||
    householdsResult.isError ||
    plansByHouseholdsIdsResult.isError;

  return {
    ...plansByHouseholdsIdsResult,
    data,
    error,
    isError,
    isPending,
    isVisible: hasVisibleDocuments,
  };
}

export function useQueryFinancialPlansDocumentsAll({
  beginDate,
  endDate,
}: {
  beginDate: string;
  endDate: string;
}) {
  const hasFinancialPlansAccess = useQueryHasFinancialPlansAccess();

  const statusResult = useQuery({
    ...allQueryKeys.externalFinancialPlanService.financialPlanStatus,
    enabled: hasFinancialPlansAccess,
  });

  const hasVisibleDocuments =
    statusResult.isSuccess && statusResult.data.hasVisibleDocuments;

  const householdsResult = useQuery({
    ...allQueryKeys.externalFinancialPlanService.households,
    enabled: hasVisibleDocuments,
  });

  const hasHouseholdsIds =
    householdsResult.isSuccess && householdsResult.data.length > 0;
  const householdsIds = hasHouseholdsIds
    ? householdsResult.data.map((el) => el.id)
    : [];

  const plansDocumentsByHouseholdsIdsResult = useQueries({
    queries: householdsIds.map((householdId) => ({
      ...allQueryKeys.externalFinancialPlanService.financialPlanDocuments({
        householdId,
        beginDate,
        endDate,
      }),
      enabled: hasHouseholdsIds,
    })),
    combine: (results) => {
      const isError = results.some((el) => el.isError === true);
      const isLoading = results.some((el) => el.isLoading === true);
      const isPending = results.some((el) => el.isPending === true);
      const isSuccess = results.every((el) => el.isSuccess === true);
      const data = isSuccess ? results.map((el) => el.data).flat() : undefined;
      const error = isError
        ? (results.find((el) => el.error != null) as unknown as Error)
        : null;
      return {
        data,
        error,
        isError,
        isLoading,
        isPending,
        isSuccess,
      };
    },
  });

  const data = plansDocumentsByHouseholdsIdsResult.data ?? [];

  const error =
    statusResult.error ??
    householdsResult.error ??
    plansDocumentsByHouseholdsIdsResult.error;

  const isPending =
    statusResult.isPending ||
    (hasVisibleDocuments &&
      (householdsResult.isPending ||
        (hasHouseholdsIds && plansDocumentsByHouseholdsIdsResult.isPending)));

  const isError =
    statusResult.isError ||
    householdsResult.isError ||
    plansDocumentsByHouseholdsIdsResult.isError;

  const isSuccess =
    statusResult.isSuccess ||
    (hasVisibleDocuments &&
      (householdsResult.isSuccess ||
        (hasHouseholdsIds && plansDocumentsByHouseholdsIdsResult.isSuccess)));

  return {
    ...plansDocumentsByHouseholdsIdsResult,
    data,
    error,
    isError,
    isPending,
    isVisible: hasVisibleDocuments,
    isSuccess: isSuccess,
  };
}

export function useQuerySuccessMeter() {
  const resultHouseholds = useQuery(
    allQueryKeys.externalFinancialPlanService.households,
  );

  const households = resultHouseholds.data?.toSorted((a, b) =>
    a.created > b.created ? -1 : 1,
  );
  const [household] = households || [];
  const { id = "" } = household || {};

  const { data, isLoading, isError } = useQuery({
    ...allQueryKeys.externalFinancialPlanService.householdSuccessMeterById({
      householdId: id,
    }),
    enabled: !!id,
  });

  return {
    data,
    isPending: isLoading || resultHouseholds.isPending,
    isError: isError || resultHouseholds.isError,
  };
}
